<template>
  <div class="row">
    <div class="col-12">
      <h2>Olá {{ user.name.split(' ')[0] }}, bem vindo ao sistema.</h2>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'dashboard',
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>
<style></style>
